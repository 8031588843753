import React from "react";
import { Container, Section } from "../../globalStyles";
import {
  FeatureText,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureRoundedCon,
  FeatureButtonWrapper,
  FeatureName,
  FeatureTextWrapper,
  FeatureLine,
  FeatureButton,
} from "./RepairStyles";
import { useNavigate } from "react-router-dom";
import { featuresData } from "../../data/FeaturesData.js";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

const RepairComp = ({ data, dataCar }) => {
	const gaEventTracker = useAnalyticsEventTracker("Jetzt Reservieren");
  let navigate = useNavigate();
  //const { title, description } = data;
  const initial = {
    y: 40,
    opacity: 0,
  };
  const animate = {
    y: 0,
    opacity: 1,
  };

  return (
    <Section smPadding="5rem 10px" position="relative" inverse id="startseite" height="100vh">
      <Container>
          <FeatureRoundedCon style={{width: "11rem"}}>{data.titleMain}</FeatureRoundedCon>
        <FeatureTextWrapper>
          <FeatureTitle  style={{paddingBottom:"1rem" , letterSpacing:"0.1rem"}}>{data.title}</FeatureTitle>
          <FeatureText>{data.description}</FeatureText>
        </FeatureTextWrapper>
        <FeatureLine />
        <FeatureWrapper>
            <FeatureColumn
              initial={initial}
              animate={animate}
              transition={{ duration: 0.5 + 1 * 0.1 }}
            >
              <FeatureName>{dataCar.title}</FeatureName>
              <FeatureText  style={{  paddingBottom: '1.5rem' }}>{dataCar.description}</FeatureText>
            </FeatureColumn>
        </FeatureWrapper>
        <FeatureButtonWrapper>
          <FeatureButton
            onClick={() => {
              navigate("/contact");
              window.scrollTo(0, 0);
			  gaEventTracker('Button Pressed');
            }}
          >
            Jetzt Reservieren
          </FeatureButton>
        </FeatureButtonWrapper>
      </Container>
    </Section>
  );
};

export default RepairComp;
